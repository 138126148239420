import React, { useEffect, useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import { memo } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import logoTraveloka from '../../../../assets/images/holiday/ihg.png';
import { FaRegListAlt } from 'react-icons/fa';

function Header(props) {
    return (
        <div className="flex items-center justify-between mt-8 w-[100%] max-w-[480px] px-4">
            <img src={logoTraveloka} alt="" width={150} />
            <Link to="/order/index">
                <FaRegListAlt color="white" size={24} />
            </Link>
        </div>
    );
}

export default memo(Header);
