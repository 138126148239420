import { Link } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Home.css';
import { useTranslation } from 'react-i18next';
import { RiAccountCircleFill } from 'react-icons/ri';
import NewRun from '../../components/NewRun/NewRun';
import blockIcon from '../../assets/images/block.png';
import grace from '../../assets/images/grace-bay-beach-turks-caicos-islands.jpg';
import ninhhai from '../../assets/images/bai-bien-ninh-chu-1.jpg';
import lescala from '../../assets/images/lescala.webp';
import montdore from '../../assets/images/montdore.webp';
import celle from '../../assets/images/Celle.jpg';
import wollongong from '../../assets/images/Wollongong.jpg';

const axios = require('axios').default;

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const formatter = new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD',
});

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function Home(args) {
    let [user, setUser] = useState({
        data: [{ money: 0, dongbangtk: 0 }],
        moneyEarn: { moneyEarnToday: 0, moneyEarnPreDay: 0 },
    });

    const [vipLevel, setVipLevel] = useState();

    const { t } = useTranslation();

    const descriptionHome = [
        {
            title: t('content.home.titleVip1'),
            image: ninhhai,
            content: t('content.home.contentVip1'),
        },
        {
            title: t('content.home.titleVip2'),
            image: grace,
            content: t('content.home.contentVip2'),
        },
        {
            title: t('content.home.titleVip3'),
            image: lescala,
            content: t('content.home.contentVip3'),
        },
        {
            title: t('content.home.titleVip4'),
            image: montdore,
            content: t('content.home.contentVip4'),
        },
        {
            title: t('content.home.titleVip5'),
            image: celle,
            content: t('content.home.contentVip5'),
        },
        {
            title: t('content.home.titleVip6'),
            image: wollongong,
            content: t('content.home.contentVip6'),
        },
    ];

    const [listMission, setListMission] = useState([
        {
            title: t('content.home.titleVip1'),
            desc: t('content.home.descVip1'),
            vip: 'VIP1',
            icon: ninhhai,
            rose: 20,
            content: 1,
            status: 0,
        },
        {
            title: t('content.home.titleVip2'),
            desc: t('content.home.descVip2'),
            vip: 'VIP2',
            icon: grace,
            rose: 25,
            content: 3,
            status: 0,
        },
        {
            title: t('content.home.titleVip3'),
            desc: t('content.home.descVip3'),
            vip: 'VIP3',
            icon: lescala,
            rose: 30,
            content: 5,
            status: 0,
        },
        {
            title: t('content.home.titleVip4'),
            desc: t('content.home.descVip4'),
            vip: 'VIP4',
            icon: montdore,
            rose: 35,
            content: 7,
            status: 0,
        },
        {
            title: t('content.home.titleVip5'),
            desc: t('content.home.descVip5'),
            vip: 'VIP5',
            icon: celle,
            rose: 30,
            content: 8,
            status: 0,
        },
        {
            title: t('content.home.titleVip6'),
            desc: t('content.home.descVip6'),
            vip: 'VIP6',
            icon: wollongong,
            rose: 30,
            content: 10,
            status: 0,
        },
    ]);

    // Click chuột ngoài vùng để đóng Modal
    // const modalRef = useRef();

    // useEffect(() => {
    //     function handleClickOutside(event) {
    //         if (modalRef.current && !modalRef.current.contains(event.target)) {
    //             setModalOne(false);
    //         }
    //     }

    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [modalRef]);

    useEffect(() => {
        checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;

                if (data.status === 'ok') {
                    setUser(data);
                    setVipLevel(data.data[0].name_level);
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }, []);

    return (
        <div id="Home">
            <div className="flex items-center justify-start mt-10">
                <RiAccountCircleFill size={48} color="white" />
                <span className="ml-4 text-white fs-1 font-semibold">
                    {t('content.home.chaoMung')} {user?.userBank?.name_u_bank}
                </span>
            </div>

            <NewRun />

            <div className="main-container bg-[#ffffff] mt-4" style={{ borderRadius: '25px' }}>
                <div className="content-home">
                    <div className="vip-wrap px-4 py-4">
                        <div className="tab">
                            <div className="tabItem activeTabItem">Membership Level</div>
                            <div className="tabItem">Partners</div>
                        </div>
                    </div>

                    <div className="vip-list flex flex-wrap items-center justify-center">
                        {vipLevel &&
                            listMission.map((item, i) => {
                                return (
                                    <div className={`cats ${item.vip === vipLevel ? 'active_cats' : ''}`} key={i}>
                                        {item.vip === vipLevel ? (
                                            <Link
                                                to="/order/mission"
                                                className="central-group-img z-10 w-[100%] h-[100%] relative flex flex-col items-center justify-between"
                                            >
                                                <img
                                                    src={item.icon}
                                                    alt=""
                                                    className="w-[100%] h-[10rem] lg:h-[12rem]"
                                                    style={{
                                                        borderTopLeftRadius: '1rem',
                                                        borderTopRightRadius: '1rem',
                                                    }}
                                                />
                                                <div className="flex items-center justify-between w-[100%] px-2 mt-2">
                                                    <span className="text-xl max-[380px]:text-lg font-bold text-[black]">
                                                        {item.title}
                                                    </span>
                                                    <span className="text-xl max-[380px]:text-lg font-bold text-[black]">
                                                        {t('content.home.hoaHong')}: {item.rose}%
                                                    </span>
                                                </div>
                                                <div className="flex items-center justify-between w-[100%] px-2 mt-2 pb-2">
                                                    <span className="text-xl text-[black] max-[380px]:text-lg">
                                                        {item.desc}
                                                    </span>
                                                    <span className="text-xl text-[black] max-[380px]:text-lg">
                                                        {item.content} {t('content.home.hanhTrinh')}
                                                    </span>
                                                </div>
                                            </Link>
                                        ) : (
                                            <div className="central-group-img z-10 w-[100%] h-[100%] relative flex flex-col items-center justify-between">
                                                <img
                                                    src={item.icon}
                                                    alt=""
                                                    className="w-[100%] h-[10rem] lg:h-[12rem]"
                                                    style={{
                                                        borderTopLeftRadius: '1rem',
                                                        borderTopRightRadius: '1rem',
                                                    }}
                                                />
                                                <div className="flex items-center justify-between w-[100%] px-2 mt-2">
                                                    <span className="text-xl font-bold text-[black] max-[380px]:text-lg">
                                                        {item.title}
                                                    </span>
                                                    <span className="text-xl font-bold text-[black] max-[380px]:text-lg">
                                                        {t('content.home.hoaHong')}: {item.rose}%
                                                    </span>
                                                </div>
                                                <div className="flex items-center justify-between w-[100%] px-2 mt-2 pb-2">
                                                    <span className="text-xl text-[black] max-[380px]:text-lg">
                                                        {item.desc}
                                                    </span>
                                                    <span className="text-xl text-[black] max-[380px]:text-lg">
                                                        {item.content} {t('content.home.hanhTrinh')}
                                                    </span>
                                                </div>
                                                <div
                                                    className="absolute top-0 bottom-0 left-0 right-0 w-[100%] h-[100%] flex items-center justify-center"
                                                    style={{
                                                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                                        borderRadius: '1rem',
                                                    }}
                                                >
                                                    <img src={blockIcon} alt="" className="" width={24} />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                    </div>

                    <div className="flex flex-col items-center justify-center mx-4 description-home py-16">
                        {descriptionHome.map((item, i) => {
                            return (
                                <div key={i} className="flex items-center justify-center overflow-hidden py-2">
                                    <img src={item.image} alt="" width={80} height={80} />
                                    <div className="ml-4">
                                        <div className="title fs-4 font-bold">{item.title}</div>
                                        <div className="content-desc">{item.content}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Home;
