import React from 'react';
import './newsrun.scss';
import bellNotification from '../../assets/images/bell_notification.png';
import { useTranslation } from 'react-i18next';

function NewRun() {
    const { t } = useTranslation();

    return (
        <div className="home-news-run mt-8">
            <div className="wrap-run flex items-center justify-center">
                <h3 className="title ml-2 pt-2 pr-2">
                    <img src={bellNotification} alt="" width={20} />
                </h3>
                <div className="wrapper">
                    <p className="target">{t('content.newRun')}</p>
                </div>
            </div>
        </div>
    );
}

export default NewRun;
