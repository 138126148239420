import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import './My.css';

import vip1icon from '../../assets/images/vip1.png';
import vip2icon from '../../assets/images/vip2.png';
import vip3icon from '../../assets/images/vip3.png';
import vip4icon from '../../assets/images/vip4.png';
import vip5icon from '../../assets/images/vip5.png';
import vip6icon from '../../assets/images/vip6.png';
import vietnam from '../../assets/images/vietnam.png';
import england from '../../assets/images/england.png';

import { BsQrCodeScan, BsFillCreditCard2FrontFill } from 'react-icons/bs';
import {
    MdOutlineSupportAgent,
    MdPassword,
    MdSecurity,
    MdOutlineGavel,
    MdQuiz,
    MdDiversity3,
    MdPowerSettingsNew,
} from 'react-icons/md';

import ProgressBar from '@ramonak/react-progress-bar';
import Loading from 'react-loading';
import { AiOutlineGlobal } from 'react-icons/ai';

const axios = require('axios').default;

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function My() {
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState({
        data: [{ money: 0, dongbangtk: 0, roses: 0 }],
        moneyEarn: { moneyEarnPreDay: 0, moneyEarnToday: 0 },
        mission: {
            amount: 0,
            result: 0,
            amountToday: 0,
        },
    });

    const [nameUserBank, setNameUserBank] = useState();
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'vi');

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);

                let data = response.data;
                if (data.status === 'ok') {
                    setUser(data);
                    if (data.userBank) {
                        setNameUserBank(data.userBank.name_u_bank);
                    } else setNameUserBank(data.data[0].username);
                }
            })
            .catch(function (error) {
                toast.error(t('content.error'), { theme: 'light' });
            });
    }, []);

    const changeLanguage = (value) => {
        setLanguage(value);
        localStorage.setItem('language', value);
        i18n.changeLanguage(value);
    };

    useEffect(() => {
        if (language) i18n.changeLanguage(language);
    }, [language]);

    return (
        <>
            <div className="account relative">
                <div className="flex flex-col items-center justify-center mt-12">
                    <span className="fs-1 text-white font-semibold">{nameUserBank}</span>
                    {/* <div className="flex items-center justify-center mt-2">
                        {user?.data[0].name_level === 'VIP1' && <img src={vip1icon} alt="" width={48} />}
                        {user?.data[0].name_level === 'VIP2' && <img src={vip2icon} alt="" width={48} />}
                        {user?.data[0].name_level === 'VIP3' && <img src={vip3icon} alt="" width={48} />}
                        {user?.data[0].name_level === 'VIP4' && <img src={vip4icon} alt="" width={48} />}
                        {user?.data[0].name_level === 'VIP5' && <img src={vip5icon} alt="" width={48} />}
                        {user?.data[0].name_level === 'VIP6' && <img src={vip6icon} alt="" width={48} />}
                        <span className="fs-2 text-white font-semibold" style={{ fontFamily: 'iconfont' }}>
                            {user?.data[0].name_level}
                        </span>
                    </div> */}
                </div>

                <div
                    className="main-container bg-[#ffffff] mt-4 overflow-x-hidden px-4"
                    style={{ borderRadius: '25px' }}
                >
                    <div className="flex items-center justify-between mt-4">
                        <Link
                            to="/my/invite"
                            className="px-8 flex items-center justify-center rounded-full py-2"
                            style={{
                                border: '1px solid #2e4fc5',
                            }}
                            state={{ inviteCode: user?.data[0].id_user }}
                        >
                            <BsQrCodeScan size={24} />
                            <span className="fs-2 ml-2">{t('content.my.xinMoi')}</span>
                        </Link>
                        {/* <Link
                            to="https://t.me/cskhtraveloka6868"
                            target="_blank"
                            className="px-4 flex items-center justify-center rounded-full py-2"
                            style={{
                                border: '1px solid #2e4fc5',
                            }}
                        >
                            <MdOutlineSupportAgent size={24} />
                            <span className="fs-2 ml-2">{t('content.my.CSKH')}</span>
                        </Link> */}
                    </div>
                    <div className="mt-24 tours-item flex flex-col items-center justify-start w-[100%] mx-2 rounded-2xl mb-4 p-4">
                        <div className="flex items-center justify-between w-[100%]">
                            <div className="fs-3 font-medium flex">
                                {t('content.my.hanhTrinhHN')}{' '}
                                <span className="fs-3 font-medium text-[#2e4fc5] ml-4">
                                    {isLoading ? (
                                        <Loading
                                            type="spinningBubbles"
                                            color="black"
                                            height={'20px'}
                                            width={'20px'}
                                            className="mb-3 ml-4"
                                        />
                                    ) : (
                                        `${user?.mission?.amountToday}/${user.data[0].amount}`
                                    )}
                                </span>
                            </div>
                            <div style={{ color: 'transparent' }}>1</div>
                        </div>

                        <ProgressBar
                            completed={`${user?.mission?.amountToday}`}
                            maxCompleted={`${user.data[0].amount}`}
                            height="5px"
                            className="mt-8 w-[100%]"
                            customLabel=""
                            isLabelVisible={false}
                            bgColor="#2e4fc5"
                        ></ProgressBar>
                    </div>

                    <div className="mt-12 tours-item flex flex-col items-center justify-center w-[100%] mx-2 rounded-2xl mb-4 p-4">
                        <div
                            className="flex items-center justify-between w-100 pb-4"
                            style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}
                        >
                            <span className="fs-3 font-medium">{t('content.my.thietLapCaNhan')}</span>
                            <div style={{ color: 'transparent' }}>1</div>
                        </div>

                        <div className="flex flex-col items-start justify-center w-[100%]">
                            <Link to="/my/edit-password" className="flex items-center justify-center my-4">
                                <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#2e4fc5] rounded-2xl">
                                    <MdPassword size={24} color="white" />
                                </div>
                                <div className="flex flex-col ml-4">
                                    <span className="fs-3">{t('content.my.mkDN')}</span>
                                    <span className="fs-4 text-[#2e4fc5]">{t('content.my.thayDoiMKDN')}</span>
                                </div>
                            </Link>
                            <Link to="/my/edit-password-transaction" className="flex items-center justify-center my-4">
                                <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#2e4fc5] rounded-2xl">
                                    <MdSecurity size={24} color="white" />
                                </div>
                                <div className="flex flex-col ml-4">
                                    <span className="fs-3">{t('content.my.mkRT')}</span>
                                    <span className="fs-4 text-[#2e4fc5]">{t('content.my.thayDoiMKRT')}</span>
                                </div>
                            </Link>
                            <Link to="/my/banking" className="flex items-center justify-center my-4">
                                <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#2e4fc5] rounded-2xl">
                                    <BsFillCreditCard2FrontFill size={24} color="white" />
                                </div>
                                <div className="flex flex-col ml-4">
                                    <span className="fs-3">{t('content.my.tkNH')}</span>
                                    <span className="fs-4 text-[#2e4fc5]">{t('content.my.suaTTTK')}</span>
                                </div>
                            </Link>
                            <Link to="/term-conditions" className="flex items-center justify-center my-4">
                                <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#2e4fc5] rounded-2xl">
                                    <MdOutlineGavel size={24} color="white" />
                                </div>
                                <div className="flex flex-col ml-4">
                                    <span className="fs-3">{t('content.my.dieuKhoan')}</span>
                                    <span className="fs-4 text-[#2e4fc5]">{t('content.my.dkvadk')}</span>
                                </div>
                            </Link>
                            <Link to="/asked-questions" className="flex items-center justify-center my-4">
                                <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#2e4fc5] rounded-2xl">
                                    <MdQuiz size={24} color="white" />
                                </div>
                                <div className="flex flex-col ml-4">
                                    <span className="fs-3">{t('content.my.cauHoiTG')}</span>
                                    <span className="fs-4 text-[#2e4fc5]">{t('content.my.cauhoitg')}</span>
                                </div>
                            </Link>
                            <Link to="/about-us" className="flex items-center justify-center my-4">
                                <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#2e4fc5] rounded-2xl">
                                    <MdDiversity3 size={24} color="white" />
                                </div>
                                <div className="flex flex-col ml-4">
                                    <span className="fs-3">{t('content.my.veChungToi')}</span>
                                    <span className="fs-4 text-[#2e4fc5]">{t('content.my.vechungtoi')}</span>
                                </div>
                            </Link>

                            <div className="flex items-center justify-between my-4 w-[100%] cursor-pointer">
                                <div className="flex items-center justify-center">
                                    <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#2e4fc5] rounded-2xl">
                                        <AiOutlineGlobal size={24} color="white" />
                                    </div>
                                    <div className="flex flex-col ml-4">
                                        <span className="fs-3">{t('content.my.ngonNgu')}</span>
                                        <span className="fs-4 text-[#2e4fc5]">{t('content.my.ngonngu')}</span>
                                    </div>
                                </div>

                                <div className="flex flex-col items-center justify-center w-[120px] bg-[#f3f3f3] text-xl rounded-2xl">
                                    {language === 'vi' && (
                                        <div
                                            className="flex items-center justify-between px-4 w-[100%] h-[50px] text-center text-xl"
                                            onClick={() => changeLanguage('en')}
                                        >
                                            <span className="">Tiếng Việt</span>
                                            <img src={vietnam} alt="" width={24} />
                                        </div>
                                    )}

                                    {language === 'en' && (
                                        <div
                                            className="flex items-center justify-between px-4 w-[100%] h-[50px] text-center text-xl"
                                            onClick={() => changeLanguage('vi')}
                                        >
                                            <span className="">English</span>
                                            <img src={england} alt="" width={24} />
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div
                                className="flex items-center justify-center my-4"
                                onClick={() => {
                                    localStorage.removeItem('auth');
                                    window.location.href = '/account/login';
                                }}
                            >
                                <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#fae3e5] rounded-2xl">
                                    <MdPowerSettingsNew size={24} color="#dc3545" />
                                </div>
                                <div className="flex flex-col ml-4">
                                    <span className="fs-3">{t('content.my.dangX')}</span>
                                    <span className="fs-4 text-[#2e4fc5]">{t('content.my.thoat')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer
                    position="top-right"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </>
    );
}

export default My;
