import React from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import { memo } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import backIcon from '../../../../assets/images/backicon.png';

function HeaderTwo(props) {
    const navigate = useNavigate();

    return (
        <div className="header-account flex items-center justify-between px-4 w-[100%] pt-8">
            <div
                onClick={() => {
                    navigate(-1);
                }}
            >
                <img src={backIcon} alt="" width={20} />
            </div>

            <div className="text-white fs-1 font-semibold">{props.title}</div>
            <div style={{ color: 'transparent' }}>11111</div>
        </div>
    );
}

export default memo(HeaderTwo);
