// import { useEffect, useState } from 'react';
// import SETTINGS from '../../setting.json';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { useTranslation } from 'react-i18next';
// import { MdLocalOffer } from 'react-icons/md';

// const axios = require('axios').default;

// function formatMoney(money = 0) {
//     return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
// }

// const formatter = new Intl.NumberFormat('en-us', {
//     style: 'currency',
//     currency: 'USD',
// });

// function Recharge({ title }) {
//     document.title = title;
//     let [select, setSelect] = useState('bank');
//     let [money, setMoney] = useState(0);

//     const { t } = useTranslation();

//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);

//     function RechargeHandler() {
//         if (!money || money < 220) return toast.warn(`{t('content.recharge.minNap')}`, { theme: 'light' });

//         const headers = {
//             'x-access-token': localStorage.getItem('auth'),
//             'Access-Control-Allow-Origin': '*',
//         };
//         axios
//             .post(
//                 `${SETTINGS.BASE_URL}/api/webapi/recharge/add`,
//                 { money, select },
//                 {
//                     headers,
//                 },
//             )
//             .then(function (response) {
//                 let data = response.data;

//                 if (data.status === 1) toast.success(data.message, { theme: 'light' });
//                 if (data.status === 2) toast.warn(data.message, { theme: 'light' });
//                 if (data.status) {
//                     setTimeout(() => {
//                         // window.location.href = `/recharge/${data.id_txn}`;
//                         window.open(
//                             '/support',
//                             '_blank', // <- This is what makes it open in a new window.
//                         );
//                     }, 1500);
//                 } else {
//                     localStorage.removeItem('auth');
//                     window.location.href = '/account/login';
//                 }
//             })
//             .catch(function (error) {
//                 toast.error(`${t('content.error')}`, { theme: 'light' });
//             });
//     }
//     return (
//         <div>
//             <div
//                 className="recharge main-container bg-[#ffffff] overflow-x-hidden flex flex-col items-center justify-center mt-16"
//                 style={{ borderRadius: '25px' }}
//             >
//                 <div className="flex bg-[#d4edda] border-[#c3e6cb] text-[#155724] rounded-3xl mt-8 p-4 mx-12">
//                     <div className="w-[40px] h-[40px] flex items-center justify-center bg-[white] rounded-full">
//                         <MdLocalOffer size={24} color="#2e4fc5" />
//                     </div>
//                     <div className="ml-4 flex flex-col items-start justify-center text-2xl w-[80%]">
//                         <span className="font-bold">{t('content.recharge.loiKhuyen')}:</span>
//                         <span className="mt-2 leading-10">{t('content.recharge.vuiLong')}!</span>
//                     </div>
//                 </div>
//                 <div>
//                     <div className="text-center my-[25px]">
//                         <p className="text-3xl text-[#005652]">{t('content.recharge.soTien')}:</p>
//                         <div className="text-[#ffaa03] text-[32px] font-bold">{formatter.format(money) || '0'}</div>
//                         <div className="form-group px-[15px] mt-[25px]">
//                             <p className="text-left ml-[10px] py-[5px] text-[14px] font-semibold">
//                                 {t('content.recharge.soLuong')}
//                             </p>
//                             <div className="flex rounded-full p-[10px]" style={{ border: '2px solid #2a313c' }}>
//                                 <span className="p-[5px] text-[#2a313c] font-semibold text-2xl">USD</span>
//                                 <input
//                                     onChange={(e) => setMoney(e.target.value)}
//                                     className="w-[100%] text-right pr-[5px] text-[18px]"
//                                     type="number"
//                                     placeholder={t('content.recharge.nhapSoTien')}
//                                     spellCheck="false"
//                                     maxLength="13"
//                                     style={{
//                                         backgroundColor: '#d8ebfe',
//                                         borderRadius: '25px',
//                                         marginLeft: '10px',
//                                     }}
//                                 />
//                             </div>
//                         </div>
//                         <div
//                             onClick={() => RechargeHandler()}
//                             className="form-group w-[100%] mx-auto px-[15px] mt-[25px]  rounded-full "
//                         >
//                             <div className="py-[10px] text-[#fff] button-recharge font-semibold rounded-full text-3xl">
//                                 {t('content.recharge.napTienNgay')}
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <ToastContainer
//                     position="top-right"
//                     autoClose={1000}
//                     hideProgressBar={false}
//                     newestOnTop={false}
//                     closeOnClick
//                     rtl={false}
//                     pauseOnFocusLoss
//                     draggable
//                     pauseOnHover
//                 />
//             </div>
//         </div>
//     );
// }

// export default Recharge;

import { useEffect, useState } from 'react';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
const axios = require('axios').default;

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' VNĐ';
}

const formatter = new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD',
});

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function randomStr(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function Recharge({ title }) {
    document.title = title;
    let [show, setShow] = useState(true);
    let [select, setSelect] = useState('bank');
    let [money, setMoney] = useState(0);
    let [user, setUser] = useState([]);
    let id_txn = randomStr(16);
    const [adminBank, setAdminBank] = useState({});
    const { t } = useTranslation();
    const [showInfobank, setShowInfobank] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setUser(data.data[0]);
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/admin/bank`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                console.log(data);
                setAdminBank(data?.result?.list?.[0]);
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }, []);
    function RechargeHandler() {
        if (!money || money < adminBank?.min_withdraw)
            return toast.warn(`${t('content.recharge.minNap')} ${formatMoney(adminBank?.min_withdraw)}`, {
                theme: 'light',
            });
        if (select === 'momo') return toast.warn(`${t('content.recharge.baoTriMomo')}`, { theme: 'light' });
        setShow(false);

        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/recharge/add`,
                { money, select, id_txn },
                {
                    headers,
                },
            )
            .then(function (response) {
                let data = response.data;
                setShow(true);
                if (data.status === 1) {
                    toast.success(data.message, { theme: 'light' });
                    setTimeout(() => {
                        window.location.href = `/`;
                    }, 1500);
                }
                if (data.status !== 1) toast.warn(data.message, { theme: 'light' });
                // if (data.status) {
                //     setTimeout(() => {
                //         // window.location.href = `/recharge/${data.id_txn}`;
                //         window.open(
                //             '/support',
                //             '_blank', // <- This is what makes it open in a new window.
                //         );
                //     }, 1500);
                // } else {
                //     localStorage.removeItem('auth');
                //     window.location.href = '/account/login';
                // }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }
    const handleConfirmMoney = () => {
        setShowInfobank(true);
    };
    return (
        <div>
            <div className="recharge">
                <div>
                    <p className="my-amount text-[15px] font-semibold text-[#ffa900] bg-[#fff8e9] py-[10px] text-center rounded-md mt-8">
                        {t('content.recharge.soDuCT')}: {formatMoney(user.money) || '0'} VNĐ
                    </p>
                    {!showInfobank ? (
                        <>
                            <div className="grid grid-cols-2 gap-4 bg-[rgb(244,244,245)] rounded p-4 text-2xl">
                                <span className=" text-[#2a313c] font-semibold text-2xl">
                                    {t('content.recharge.soTien')}:
                                </span>
                                <input
                                    onChange={(e) => setMoney(e.target.value)}
                                    className="text-right pr-[5px] text-[18px] p-1"
                                    type="tel"
                                    placeholder={t('content.recharge.nhapSoTien')}
                                    spellCheck="false"
                                    maxLength="13"
                                    style={{
                                        backgroundColor: '#d8ebfe',
                                        borderRadius: '5px',
                                        marginLeft: '10px',
                                    }}
                                />
                            </div>
                            <div onClick={handleConfirmMoney} className="w-[100%] mx-auto px-[15px] mt-[25px] ">
                                <div className="py-[10px] text-[#fff]  font-semibold rounded-md text-center text-3xl bg-[#e9c39f]">
                                    {t('content.recharge.xacNhan')}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="text-center my-[25px]">
                                {/* <p className="text-3xl text-[#005652] font-bold">Nạp tiền</p> */}
                                <div className="form-group p-[15px] mt-[25px] bg-zinc-100 rounded-md">
                                    <div className="flex items-center justify-between">
                                        <span className="text-2xl">{t('content.recharge.donHangSo')}: </span>
                                        <span className="text-2xl">{id_txn}</span>
                                        <div
                                            onClick={() => {
                                                toast.success(`${t('content.formRecharge.saoChepThanhCong')}`, {
                                                    theme: 'light',
                                                });
                                                copy(id_txn);
                                            }}
                                            className="flex items-center justify-center rounded-full bg-cyan-500"
                                        >
                                            <p className="text-[#fff] p-2 mb-0">{t('content.recharge.saoChep')}</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="flex items-center justify-between">
                                        <span className="text-2xl">{t('content.recharge.tenNH')}: </span>

                                        <span className="text-2xl w-[30%]">
                                            {money <= 1000000 ? adminBank?.name_bank : adminBank?.name_bank_1m}
                                        </span>
                                        <div
                                            onClick={() => {
                                                toast.success(`${t('content.formRecharge.saoChepThanhCong')}`, {
                                                    theme: 'light',
                                                });
                                                copy(money <= 1000000 ? adminBank?.name_bank : adminBank?.name_bank_1m);
                                            }}
                                            className="flex items-center justify-center rounded-full bg-cyan-500"
                                        >
                                            <p className="text-[#fff] p-2 mb-0">{t('content.recharge.saoChep')}</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="flex items-center justify-between">
                                        <span className="text-2xl">{t('content.recharge.tenNN')}: </span>

                                        <span className="text-2xl">
                                            {money <= 1000000 ? adminBank?.name_u_bank : adminBank?.name_u_bank_1m}
                                        </span>
                                        <div
                                            onClick={() => {
                                                toast.success(`${t('content.formRecharge.saoChepThanhCong')}`, {
                                                    theme: 'light',
                                                });
                                                copy(
                                                    money <= 1000000
                                                        ? adminBank?.name_u_bank
                                                        : adminBank?.name_u_bank_1m,
                                                );
                                            }}
                                            className="flex items-center justify-center rounded-full bg-cyan-500"
                                        >
                                            <p className="text-[#fff] p-2 mb-0">{t('content.recharge.saoChep')}</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="flex items-center justify-between">
                                        <span className="text-2xl">{t('content.recharge.soTK')}: </span>

                                        <span className="text-2xl">
                                            {money <= 1000000 ? adminBank?.stk_bank : adminBank?.stk_bank_1m}
                                        </span>
                                        <div
                                            onClick={() => {
                                                toast.success(`${t('content.formRecharge.saoChepThanhCong')}`, {
                                                    theme: 'light',
                                                });
                                                copy(money <= 1000000 ? adminBank?.stk_bank : adminBank?.stk_bank_1m);
                                            }}
                                            className="flex items-center justify-center rounded-full bg-cyan-500"
                                        >
                                            <p className="text-[#fff] p-2 mb-0">{t('content.recharge.saoChep')}</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="flex justify-between ">
                                        <span className=" text-[#2a313c] font-semibold text-2xl">
                                            {t('content.recharge.soTien')}:
                                        </span>
                                        <input
                                            // onChange={(e) => setMoney(e.target.value)}
                                            value={money}
                                            disabled={true}
                                            className="text-right pr-[5px] text-[18px]"
                                            type="tel"
                                            placeholder={t('content.recharge.nhapSoTien')}
                                            spellCheck="false"
                                            maxLength="13"
                                            style={{
                                                backgroundColor: '#d8ebfe',
                                                borderRadius: '5px',
                                                marginLeft: '10px',
                                            }}
                                        />
                                    </div>
                                    <hr />
                                    <span className="text-xl">{t('content.recharge.chuY')}</span>
                                    <hr />
                                    <img
                                        src={money < 1000000 ? adminBank?.link_image_qr : adminBank?.link_image_qr_1m}
                                        alt=""
                                        className="mx-auto rounded-2xl"
                                    />
                                </div>
                                {show && (
                                    <div
                                        onClick={() => RechargeHandler()}
                                        className="w-[100%] mx-auto px-[15px] mt-[25px] "
                                    >
                                        <div className="py-[10px] text-[#fff]  font-semibold rounded-md text-3xl bg-[#e9c39f]">
                                            {t('content.recharge.xacNhan')}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>

                <ToastContainer
                    position="top-right"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </div>
    );
}

export default Recharge;
