import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import backIcon from '../../assets/images/backicon.png';
import descriptionIcon from '../../assets/images/description.png';
import { useNavigate } from 'react-router-dom';

function DieuKhoan(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    const { t } = useTranslation();

    return (
        <div className="withdraw px-[15px] py-[20px] min-h-[920px] bg-login h-[auto]">
            <div className="header-account flex items-center justify-between">
                <div
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <img src={backIcon} alt="" width={20} />
                </div>

                <div className="text-white fs-1 font-semibold">{props.title}</div>
                <div style={{ color: 'transparent' }}>11111</div>
            </div>

            <div className="bg-[#fff] text-2xl rounded-2xl py-4 px-4 leading-10 mt-8">
                <div className="flex items-center justify-start pb-2">
                    <img src={descriptionIcon} alt="" className="image-desc-term" />
                    <span className="ml-4 font-semibold">{t('content.dieuKhoan.dieuKhoan')}</span>
                </div>
                <hr />
                <br />
                <span>1. {t('content.dieuKhoan.moiNguoi')}</span>
                <br />
                <br />
                <span>2. {t('content.dieuKhoan.moiSDT')}</span>
                <br />
                <br />
                <span>3. {t('content.dieuKhoan.vuiLong')}</span>
                <br />
                <br />
                <span>4. {t('content.dieuKhoan.khongTietLo')}</span>
                <br />
                <br />
                <span>5. {t('content.dieuKhoan.tatCa')}</span>
                <br />
                <br />
                <span>6. {t('content.dieuKhoan.hanhDong')}</span>
                <br />
                <br />
                <span>7. {t('content.dieuKhoan.xacNhan')}</span>
                <br />
                <br />
                <span>8. {t('content.dieuKhoan.neuTienGui')}</span>
                <br />
                <br />
                <span>9. {t('content.dieuKhoan.soDu')}</span>
                <br />
                <br />
                <span>10. {t('content.dieuKhoan.sauKhi')}</span>
                <br />
                <br />
                <span>11. {t('content.dieuKhoan.moiTK')}</span>
                <br />
                <br />
                <span>{t('content.dieuKhoan.kinhGui')}</span>
                <br />
                <br />
                <span>{t('content.dieuKhoan.banQuyen')}</span>
            </div>
        </div>
    );
}

export default DieuKhoan;
